<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("incidence_out.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("incidence_out.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{$t('title.title_inci')}}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.INC_PER_ADD_01)
              "
              :disabled="!branchOffice"
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i>{{ $t("global.create_incident") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-alert-triangle"></i>
                      {{ $t("incidence_out.incidence_out") }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-show="showAction(1)">
                <div class="p-2" id="list">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="list"
                    :search="search"
                    height="auto"
                    fixed-header
                    :footer-props="{
                      'items-per-page-text': $t('table.item_table'),
                      'items-per-page-all-text': $t('table.all'),
                      'items-per-page-options': [15, 30, 50, 100],
                    }"
                    :loading-text="$t('table.loading')"
                    :no-data-text="$t('table.no_results_available')"
                    :no-results-text="$t('table.no_results_search')"
                  >
                    <template v-slot:top>
                      <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 ">
                          <v-text-field
                            v-model="search"
                            :label="$t('table.search')"
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.reason`]="{ item }">
                      <div>
                        <small>
                          {{ item.reason }}
                        </small>
                      </div>
                    </template>
                    <template v-slot:[`item.actions2`]="{ item }">
                      <div
                        v-if="withPermissionName(permissions.INC_PER_DETA_03)"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="ml-3 hand"
                              @click="view(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i class="feather icon-eye"></i>
                            </span>
                          </template>
                          <span>{{ $t("table.detail") }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:[`item.actions1`]="{ item }">
                      <div
                        v-if="withPermissionName(permissions.INC_PER_CONF_02)"
                      >
                        <v-switch
                          color="success"
                          class="hand"
                          dense
                          @change="confirmchangeStatus(item)"
                          inset
                          v-model="item.resolved"
                        >
                        </v-switch>
                      </div>
                      <div v-else>
                        {{ !item.resolved ? "Sin resolver" : "Resuelta" }}
                      </div>
                    </template>
                  </v-data-table>
                  <ButtonPrintComponent />
                </div>
              </div>
              <div v-if="showAction(2)">
                <div class="card">
                  <div class="card-body">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="row">
                          <div class="col-md-4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="type_incidence"
                              rules="required"
                            >
                              <v-autocomplete
                                auto-select-first
                                :items="typesIncidence"
                                :no-data-text="$t('global.no_result')"
                                attach
                                v-model="model.typeIncidence"
                                :error-messages="errors"
                                clearable
                                required
                                :label="$t('label.type_incidence')"
                              >
                              </v-autocomplete>
                            </validation-provider>
                          </div>
                          <div class="col-md-8">
                            <validation-provider
                              v-slot="{ errors }"
                              name="product_supplier"
                              rules="required"
                            >
                              <v-autocomplete
                                auto-select-first
                                :items="suppliersBranchs"
                                :no-data-text="$t('global.no_result')"
                                attach
                                v-model="model.supplierProduct"
                                :error-messages="errors"
                                clearable
                                required
                                :label="$t('label.product_supplier')"
                              >
                              </v-autocomplete>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-md-12 pb-0">
                            <strong
                              ><span>
                                <h6>
                                  {{ $t("incidence_out.origin_of_incidence") }}
                                </h6>
                              </span>
                            </strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="card check">
                              <v-list-item>
                                <v-list-item-action>
                                  <v-checkbox
                                    class="mr"
                                    v-model="model.isStock"
                                    @change="validateCheckBox(2)"
                                  />
                                </v-list-item-action>
                                <v-list-item-content>
                                  <h6 class="mb-0">
                                    {{ $t("label.product_in_stock") }}
                                  </h6>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="card check">
                              <v-list-item>
                                <v-list-item-action>
                                  <v-checkbox
                                    v-model="model.isProduct"
                                    @change="validateCheckBox(1)"
                                  >
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <h6 class="mb-0">
                                    {{ $t("label.product_not_stock") }}
                                  </h6>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="product"
                              rules="required"
                            >
                              <v-autocomplete
                                :disabled="!model.isProduct && !model.isStock"
                                @change="getDetailStock"
                                auto-select-first
                                :items="products"
                                :no-data-text="$t('label.no_results')"
                                attach
                                v-model="model.product"
                                :error-messages="errors"
                                clearable
                                :label="$t('label.product')"
                              >
                                <template slot="item" slot-scope="data">
                                  <div class="pt-5">
                                    {{ data.item.text }}
                                  </div>
                                </template>

                                <template slot="selection" slot-scope="data">
                                  <div class="pt-5">
                                    {{ data.item.text }}
                                  </div>
                                </template>
                                <!-- Fin cambios -->
                              </v-autocomplete>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="row" v-if="detailStock && model.product">
                          <div class="col-md-4">
                            <strong>{{ $t("incidence_out.id_stock") }}</strong>
                            {{ detailStock.idStock }}
                          </div>
                          <div class="col-md-4">
                            <strong>{{
                              $t("incidence_out.expiration")
                            }}</strong>
                            {{ detailStock.expirationDate }}
                          </div>
                          <div class="col-md-4">
                            <strong>{{
                              $t("incidence_out.quantity_in_stock")
                            }}</strong>
                            {{ detailStock.quantity }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="reason"
                              rules="required"
                            >
                              <v-textarea
                                outlined
                                v-model="model.reason"
                                :label="$t('label.reason')"
                                :error-messages="errors"
                                counter
                                rows="3"
                                max="200"
                                oninput="this.value = this.value.toUpperCase()"
                                append-icon="mdi-comment"
                              ></v-textarea>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button
                              class="btn btn-outline-success"
                              type="submit"
                              :disabled="invalid || sending"
                            >
                              <i class="feather icon-save mr-1"></i>
                              {{ $t("global.save_incidence") }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
              <div v-if="showAction(4)">
                <div class="card">
                  <div class="card-body">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="row p-2 pb-0">
                          <div class="col-md-3">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idIncidence"
                              :label="$t('label.id_inciden')"
                              required
                              autocomplete="off"
                            >
                            </v-text-field>
                          </div>
                          <div class="col-md-3">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.typeIncidenceName"
                              :label="$t('label.type_incidence')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                          <div class="col-md-3" v-if="model.idOrder">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idOrder"
                              :label="$t('label.order_id')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                          <div class="col-md-3" v-if="model.idStock">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.idStock"
                              :label="$t('label.id_inciden_stock')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                          <div class="col-md-3" v-if="model.lot">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.lot"
                              :label="$t('label.lot')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row p-2 pt-0">
                          <div class="col-md-8">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.supplierName"
                              :label="$t('label.supplier*')"
                              required
                              autocomplete="off"
                              append-icon="mdi-map-marker"
                            ></v-text-field>
                          </div>
                          <div class="col-md-4">
                            <v-text-field
                              :disabled="isView"
                              v-model="model.dateIncidence"
                              :label="$t('label.incident_date*')"
                              required
                              autocomplete="off"
                              append-icon="mdi-calendar"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <v-textarea
                              outlined
                              v-model="model.infoProduct"
                              disabled
                              counter
                              :label="$t('label.product_information*')"
                              rows="3"
                              class="mx-2"
                            ></v-textarea>
                          </div>
                          <div class="col-md-12">
                            <v-textarea
                              outlined
                              v-model="model.reason"
                              disabled
                              counter
                              :label="$t('label.reason')"
                              append-icon="mdi-comment"
                              class="mx-2"
                            ></v-textarea>
                          </div>
                        </div>

                        <div class="col-md-12 text-right">
                          <button
                            class="btn btn-success"
                            v-if="!showAction(4)"
                            type="submit"
                            :disabled="invalid || sending"
                          >
                            <i class="feather icon-save mr-1"></i>{{ $t("global.save") }}
                          </button>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Rightbar -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            <small style="font-size: 75%;">{{
              $t("incidence_out.title")
            }}</small>
          </v-card-title>
          <v-card-text>
            {{ $t("incidence_out.sub_title") }}
            <strong>{{ model.idIncidence }}</strong>
            {{ $t("incidence_out.sub_title_two") }}<br />
            <strong>{{ $t(changeStatus.description) }}</strong>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-outline-success mr-2"
              @click="updateStatus"
              :disabled="sending"
            >
              {{ $t("global.yes_continue") }}
            </button>
            <button
              class="btn btn-outline-danger"
              @click="cancelchangeStatus"
              :disabled="sending"
            >
              {{ $t("global.cancel") }}
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
  <!-- End Containerbar -->
  <!-- Start js -->
</template>

<script>
// Vuex
import { mapState, mapMutations } from "vuex";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { UtilFront, PermissionConstants } from "@/core";
import {
  IncidenceOrderRequest,
  BranchOfficeRequest,
  SupplierRequest,
  ProductsRequest,
  StockRequest,
  ExcelRequest,
} from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { INCIDENCE_ORDER_OUT } from "@/core/DataTableHeadersLocale";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  data() {
    return {
      permissions: PermissionConstants,
      title: "incidence_out.incidence_out",
      list: [],
      headers: INCIDENCE_ORDER_OUT(),
      search: "",
      action: 1,
      sending: false,
      dialog: false,
      isView: false,
      listTwo: [],
      searchTwo: "",
      model: {},
      branchOffice: {},
      branchOffices: [],
      typesIncidence: [],
      suppliersBranchs: [],
      products: [],
      detailStock: null,
      orders: [],
      changeStatus: {
        question: "",
        description: "",
      },
      loading: false,
    };
  },
  created() {
    this.branchOffice = this.branchOfficeState;
    this.updateAction(1);
    this.listAll();
    this.action = 1;
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      if (this.action == 1) {
        return "Inicidencias";
      } else if (this.action == 2) {
        return "Inicidencias";
      } else if (this.action == 3) {
        return "Inicidencias";
      } else if (this.action == 4) {
        return "Información de incidencia";
      }
      return "Inicidencias";
    },
  },
  methods: {
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
      } else {
        this.action = action;
        this.isView = action == 4;
        this.model.idBranchOffice = this.branchOffice.idBranchoffice;
        this.model.idUser = UtilFront.getDataUser().idUser;
      }
      if (this.action == 2) {
        this.listTypesIncidence();
        this.listAllSupplierAndBranchOffices();
      }
      this.updateAction(action);
    },
    doAction() {
      if (this.action == 2) {
        this.addIncidenceOut();
      } else if (this.action == 3) {
        this.update();
      }
    },
    addIncidenceOut() {
      this.sending = true;
      IncidenceOrderRequest.add(this.model)
        .then((res) => {
          let { message, success } = res.data.data;
          if (success) {
            this.listAll();
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    listAll() {
      this.list = [];
      this.loading = UtilFront.getLoadding();
      if (this.branchOffice != null) {
        IncidenceOrderRequest.listAllOut(this.branchOffice.idBranchoffice)
          .then((res) => {
            let { data, success, message } = res.data;
            if (success) {
              this.list = data.map((obj) => {
                obj.infoProduct = `${obj.productKey} - ${obj.productName}; ${obj.productDescription}`;
                obj.idStock = obj.idStock || "";
                obj.idOrder = obj.idOrder || "";
                obj.lot = obj.lot || "";
                return obj;
              });
            } else {
              this.list = [];
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    view(object) {
      Object.assign(this.model, object);
      this.changeAction(4);
    },
    listAllByBranchOffice() {
      this.list = [];
      IncidenceOrderRequest.listAllByBranchOffice(
        this.branchOffice.idBranchoffice
      )
        .then((res) => {
          let { data } = res.data;
          if (data.objects) {
            this.list = data.objects.map((obj) => {
              if (obj.origin) {
                obj.fullNameOrigin = `${obj.origin.name} - (${obj.origin.branchOfficeKey})`;
              }
              return obj;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    listTypesIncidence() {
      this.typesIncidence = [];
      IncidenceOrderRequest.listTypesIncidence()
        .then((res) => {
          let { data, success } = res.data.data;
          if (success == true) {
            this.typesIncidence = data;
          } else {
            this.typesIncidence = [];
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    listAllSupplierAndBranchOffices() {
      this.suppliersBranchs = [];
      BranchOfficeRequest.listAllActive()
        .then((res) => {
          let { data } = res.data.data;
          if (data) {
            this.suppliersBranchs = data
              .filter((obj) => {
                return obj.idBranchOffice != this.branchOffice.idBranchOffice;
              })
              .map((obj) => {
                return {
                  value: {
                    idBranchOffice: obj.idBranchOffice,
                  },
                  text: `${obj.name} - (${obj.branchOfficeKey})`,
                };
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          SupplierRequest.listAll()
            .then((response) => {
              let { objects } = response.data.data;
              if (objects) {
                let suppliers = objects.map((obj) => {
                  return {
                    value: {
                      idSupplier: obj.idSupplier,
                    },
                    text: `(${obj.supplierKey}) ${obj.name}`,
                  };
                });
                this.suppliersBranchs = this.suppliersBranchs.concat(suppliers);
              }
            })
            .catch((error) => {
              console.log(error);
              this.alert("internal");
            })
            .finally(() => {});
        });
    },
    listAllProductsStock() {
      if (this.branchOffice != null) {
        StockRequest.listAllStockWithExistenceByBranchOffice({
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: 1,
          soldOut: false,
        })
          .then((response) => {
            if (response.data.data.objects) {
              this.products = response.data.data.objects.map((obj) => {
                return {
                  text: `ID Stock -${obj.idStock}- Lote ${obj.lote} - (${obj.productKey}) - ${obj.productName} | ${obj.productDescripcion}`,
                  value: {
                    idProduct: obj.idProduct,
                    idStock: obj.idStock,
                  },
                };
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {});
      }
    },
    validateCheckBox(key) {
      this.products = [];
      this.model.product = null;
      if (key == 1) {
        this.listAllProducts();
        this.model.isStock = false;
      } else if (key == 2) {
        this.listAllProductsStock();
        this.model.isProduct = false;
      } else {
        this.model.isStock = false;
        this.model.isProduct = false;
      }
    },
    listAllProducts() {
      ProductsRequest.listAll()
        .then((response) => {
          if (response.data.data.data) {
            this.products = response.data.data.data.map((obj) => {
              return {
                text: `(${obj.productKey}) - ${obj.name} | ${obj.description}`,
                value: {
                  idProduct: obj.idProduct,
                  idStock: null,
                },
              };
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    updateStatus() {
      this.sending = true;
      let params = {
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
        idIncidence: this.model.idIncidence,
        status: this.model.resolved ? 1 : 0,
      };
      IncidenceOrderRequest.updateStatus(params)
        .then((response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.alert("success", "Update_Status_0001");
            this.listAll();
          } else {
            this.model.resolved = !this.model.resolved;
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.model.resolved = !this.model.resolved;
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
          this.dialog = false;
        });
    },
    confirmchangeStatus(item) {
      this.model = item;
      if (!item.resolved) {
        this.changeStatus.description = "incidence_out.pending";
      } else {
        this.changeStatus.description = "incidence_out.resolved";
      }
      this.dialog = true;
    },
    cancelchangeStatus() {
      this.dialog = false;
      this.model.resolved = !this.model.resolved;
    },
    getDetailStock() {
      this.detailStock = null;
      if (
        this.model.isStock &&
        this.model.product != null &&
        this.model.product.idStock
      ) {
        StockRequest.getDetailFull(this.model.product.idStock)
          .then((res) => {
            let { code, data, status } = res.data;
            if (data.success == true) {
              this.detailStock = data.object;
            } else {
              console.log(error);
              this.alert("internal");
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {});
      }
    },
    withPermission(role) {
      return UtilFront.withPermission(role);
    },
    excel() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportIncidence({
        id: this.branchOffice.idBranchoffice,
      });
    },
  },
  watch: {
    branchOfficeState() {
      this.branchOffice = this.branchOfficeState;
      this.listAll();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = INCIDENCE_ORDER_OUT();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
    ButtonPrintComponent,
  },
};
</script>

<style>
.check {
  border: 1px solid #ced4da;
  border-radius: 8px !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px !important;
}
</style>
